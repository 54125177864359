<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    elevation-5
    @keydown.esc="cancel"
  >
    <v-card :id="type" class="dialog">
      <v-card-title class="py-2">
        <h5 class="title-card-dialog">
         <span class="titledialogvue">{{ title }}</span>
        </h5>
      </v-card-title>
      <v-card-text v-show="!!message" class="pa-4">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn v-if="type === 'si'" color="red white--text" depressed @click.native="cancel(1)">
          <v-icon small size="15" class="mr-1"> fa-solid fa-minus </v-icon>
          {{ $t("components.app.dialog.no") }}
        </v-btn>
        <v-btn v-if="!options.noconfirm" color="grey" text @click.native="cancel(0)">
          <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
          {{ $t("components.app.dialog.cancel") }}
        </v-btn>
        <v-btn
          v-if="type === 'eliminar' || type === 'rechazar' || type === 'aceptar' || type === 'Si, Cancelar' || type === 'Si, Rechazar' || type === 'si'"
          :color="color"
          depressed
          @click.native="agree"
        >
          <v-icon size="15" class="mr-1">
            {{ icon }}
          </v-icon>
          {{ text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      type: "",
      options: {
        width: 400,
        noconfirm: false,
      },
    };
  },
  computed: {
    icon() {
      return this.type === "eliminar"
        ? "fa-solid fa-trash"
        : this.type === "rechazar"
        ? "fa-solid fa-circle-xmark"
        : this.type === "Si, Cancelar"
        ? ""
        : this.type === "Si, Rechazar"
        ? ""
        : this.type === "aceptar"
        ? ""
        : this.type === "si"
        ? "fa-solid fa-check"
        : "fa-solid fa-circle-exclamation";
    },
    color() {
      return this.type === "eliminar"
        ? "red darken-2 white--text"
        : this.type === "rechazar"
        ? "red darken-2 white--text"
        : this.type === "Si, Cancelar"
        ? "secondary white--text"
        : this.type === "Si, Rechazar"
        ? "secondary white--text"
        : this.type === "aceptar"
        ? "secondary white--text"
        : this.type === "si"
        ? "primary white--text"
        : "";
    },
    text() {
      return this.type === "eliminar"
        ? this.$t('components.app.dialog.delete')
        : this.type === "rechazar"
        ? this.$t('components.app.dialog.reject')
        : this.type === "Si, Cancelar"
        ? this.$t('components.app.dialog.cancelprocess')
        : this.type === "Si, Rechazar"
        ? this.$t('components.app.dialog.rejectoffer')
        : this.type === "aceptar"
        ? this.$t('components.app.dialog.accept')
        : this.type === "si"
        ? this.$t('components.app.dialog.yes')
        : this.$t('components.app.dialog.no_info');
    },
  },
  methods: {
    open(title, message, type, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.type = type;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel(val) {
      if (this.type == "si" && val == 1) {
        this.resolve(false);
      }
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
#eliminar .title-card-dialog,
#rechazar .title-card-dialog {
  font-size: 0.7em !important;
  font-weight: 400 !important;
  color: #fff !important;
}

#eliminar .title-card-dialog,
#rechazar .title-card-dialog,
#si .title-card-dialog,
#aceptar .title-card-dialog {
  font-size: 0.7em !important;
  font-weight: 400 !important;
  color: #fff !important;
}

#eliminar .v-card__title,
#aceptar .v-card__title,
#si .v-card__title,
#rechazar .v-card__title {
  background-color: #fff !important;
}

.titledialogvue {
  color: #0B0045;
  padding-left: 15px;
  font-size: 16px;
  font-weight: bolder;
}

#rechazar .v-card__text {
  text-align: center;
}

#eliminar .title-card-dialog .fa-solid,
#aceptar .title-card-dialog .fa-solid,
#si .title-card-dialog .fa-solid,
#rechazar .title-card-dialog .fa-solid {
  border-right: 1px solid #efefef;
  padding: 0.5em;
  color: #fff !important;
  margin-right: 0.4em;
}

.title-card-dialog {
  font-size: 0.7em !important;
  font-weight: 400 !important;
  color: #f05a24 !important;
}

.dialog .v-card__title {
/* background-color: #f05a24 */
}

.title-card-dialog .fa-solid {
  border-right: 1px solid #efefef;
  padding: 0.5em;
  color: #000 !important;
  margin-right: 0.4em;
}

.dialog .v-card__text {
  border-top: 1px solid #efefef;
  color: #666666;
  font-size: 16px;
}
</style>
