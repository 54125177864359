<template>
  <v-container :class="!secondary ? 'px-4 pt-0 border border_primary' : ''">
    <v-row no-gutters class="mb-2">

      <v-col v-if="searchOn" md="5" sm="4" class="mr-2">
        <v-text-field
          v-model="search"
          prepend-icon="fa-solid fa-magnifying-glass"
          outlined
          dense
          hide-details
          clearable
          @keydown.enter="loadData()"
          @click:clear="cleanField()"
          :label="$t('components.app.datatable.search')"
        />
      </v-col>

      <v-col v-if="updateOn" cols="auto" class="mt-2 loadtablebtn">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              large
              text
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="loadDataNew()"
            >
              <v-icon small class="mr-1" > fa-solid fa-rotate-right </v-icon>

            </v-btn>
          </template>
          <span>{{ $t("components.app.datatable.update") }}</span>
        </v-tooltip>
      </v-col>

      <slot name="exportBD" />
      <v-col v-if="exportOn" cols="auto" class="mt-2">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              class="px-3"
              v-bind="attrs"
              large
              v-on="on"
            >
              <v-icon class="mr-1" small> fa-solid fa-file </v-icon>
              {{ $t("components.app.datatable.export") }}
              <v-icon right> fa-solid fa-angle-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item id="btn-xls" @click.stop="descargar('xlsx')">
              <v-list-item-title>
                <v-icon small class="mr-1"> fa-solid fa-file-excel </v-icon>
                Excel
              </v-list-item-title>
            </v-list-item>
            <v-list-item id="btn-csv" @click.stop="descargar('csv')">
              <v-list-item-title>
                <v-icon small class="mr-1"> fa-solid fa-file-csv </v-icon>
                CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item id="btn-copy" @click.stop="descargar('copy')">
              <v-list-item-title>
                <v-icon small class="mr-1"> fa-solid fa-copy </v-icon>
                {{ $t("components.app.datatable.clipboard") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-if="columnsOn" cols="auto" class="mt-2" sm="1" md="1" lg="auto">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  text
                  large
                  icon
                  :small="$vuetify.breakpoint.xs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon class="mr-1" size="20">
                    fa-solid fa-ellipsis-vertical
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.app.datatable.hide_columns") }}</span>
            </v-tooltip>
          </template>
          <div id="modal-columns">
            <v-select
              v-model="selectedHeaders"
              dense
              hide-details
              :items="headersMap"
              class="hide-columns"
              :label="$t('components.app.datatable.select_columns')"
              multiple
              :no-data-text="
                $t('components.app.datatable.no_results_not_found')
              "
              outlined
              return-object
            >
              <template #selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text caption">
                  ( + {{ selectedHeaders.length - 2 }} mas)
                </span>
              </template>
            </v-select>
            <v-btn x-small depressed color="secondary" @click="restartColum()">
              {{ $t("components.app.datatable.restar_column") }}
            </v-btn>
          </div>
        </v-menu>
      </v-col>
      <v-col v-if="newOn" cols="auto" class="mt-2" sm="1" md="1" lg="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              large
              text
              icon
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click.stop="$emit('newRecord')"
            >
              <v-icon small class="mr-1"> fa-solid fa-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.app.datatable.new_record") }}</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="uploadOn" cols="auto" class="mt-2" sm="1" md="1" lg="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              large
              text
              icon
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click.stop="showModalUpload()"
            >
              <v-icon small class="mr-1"> fa-solid fa-file-arrow-up </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.app.datatable.upload_bulk") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-if="massiveOfferOn"> </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col cols="12">
        <v-data-table
          :headers="tHeader"
          :key="anIncreasingNumber"
          :items="items"
          v-sortable-table="{ onEnd: this.sortTheHeadersAndUpdateTheKey }"
          id="virtual-scroll"
          v-scroll.self="onScroll"
          v-bind="$attrs"
          :loading="loader"
          :loading-text="$t('components.app.datatable.loading_data')"
          :class="loadCursor"
          disable-pagination
          hide-default-footer
          disable-sort
          fixed-header
          v-on="$listeners"
          :style="computedScroll"
        >

          <template
            v-if="filtersOn"
            v-for="header in headers"
            v-slot:[`header.${header.value}`]
            #activator="{ on, attrs }"
          >
            <div class="header-item">
              <div class="header-text">
                <span class="header-title"> {{ header.text }}</span>
              </div>
              <div class="header-btn">
                <template>
                  <v-btn
                    icon
                    text
                    @click="loadFilter(header.name)"
                    :disabled="filterFlag"
                    v-bind:color="
                      selectedFilter[header.name]?.length == 0
                        ? 'primary'
                        : 'tercery'
                    "
                  >
                    <v-icon size="small"> fa fa-filter </v-icon>
                  </v-btn>
                  <SelectAutocompleteFilter
                    multiple
                    :url="header.urlFilter"
                    v-model="header.vmodel"
                    :selected="selectedFilterItems(header.name)"
                    :label="$t('components.app.datatable.filters')"
                    :clearable="false"
                    class="menuFilter"
                    v-show="header.showFilter"
                    @change="selectedFilterOnClick(header.vmodel, header.name, selectedFilterOnClickURL)"
                    outlined
                    :menu-props="{ maxWidth: 180 }"
                    ref="selectFilter"
                  >
                    <template v-slot:selection="{ item }" />
                  </SelectAutocompleteFilter>
                </template>
              </div>
            </div>
          </template>

          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>

          <template #no-data>
            <p>{{ $t("components.app.datatable.no_results_not_found") }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="informacion-page">
      {{ noPagination ? "" : info }}
    </div>
    <v-dialog v-model="modalUpload" scrollable width="30%" persistent>
      <v-card class="param">
        <v-card-title>
          <h4 class="title-card">
            <v-icon>fa-solid fa-file-arrow-up</v-icon>
            <span>{{ $t("components.app.datatable.upload_bulk") }}</span>
          </h4>
          <v-spacer />
          <v-btn depressed text color="grey" @click.stop="closeModalUpload()">
            <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valida" dense>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>{{
                    $t("components.app.datatable.text_upload")
                  }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-2 pb-0 mx-2">
                  <v-file-input
                    v-model="file"
                    show-size
                    class="pt-1 upload-file"
                    prepend-icon="fa-solid fa-file-excel"
                    @click:clear="clearFile()"
                    :rules="[fileSize(100000000)]"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="border-top my-0">
          <v-spacer />
          <v-btn
            color="light-green darken-3"
            :disabled="!valida"
            depressed
            class="white--text"
            @click="loadDataUpload()"
          >
            <v-icon small class="fa-solid fa-file-arrow-up mr-1" />
            {{ $t("components.app.datatable.upload_bulk") }}
          </v-btn>
          <v-btn depressed @click.stop="closeModalUpload()">
            <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
            {{ $t("components.app.datatable.exit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import validate from "@/plugins/validate";
import Sortable from "sortablejs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DataTable",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    urlCount: {
      type: String,
      default: "",
    },
    urlUpload: {
      type: String,
      default: "",
    },
    searchP: {
      type: String,
      default: "",
    },
    newOn: {
      type: Boolean,
      default: false,
    },
    uploadOn: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
    actionsOn: {
      type: Boolean,
      default: false,
    },
    dragColumnsOn: {
      type: Boolean,
      default: false,
    },
    searchOn: {
      type: Boolean,
      default: false,
    },
    exportOn: {
      type: Boolean,
      default: false,
    },
    updateOn: {
      type: Boolean,
      default: false,
    },
    columnsOn: {
      type: Boolean,
      default: false,
    },
    noPagine: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    filtersOn: {
      type: Boolean,
      default: false,
    },
    urlFilter: {
      type: String,
      default: "",
    },
    massiveOfferOn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ...validate,
    localItems: null,
    loader: false,
    newItems: [],
    timeout: null,
    length: 0,
    file: [],
    itemsSelect: [],
    totalItems: 0,
    urlNew: "",
    perPage: 20,
    rowsConsult: 0,
    totalPages: 0,
    local: 0,
    pageUpdate: 0,
    selectedHeaders: [],
    headersMap: [],
    valida: false,
    modalUpload: false,
    search: "",
    anIncreasingNumber: 1,
    filterActive: false,
    selectedFilter: {
      TypeOperations: [],
      TripNumber: [],
      OriginCities: [],
      OriginAddress: [],
      OriginDate: [],
      DestiniesCities: [],
      DestiniesAddress: [],
      DestiniesDate: [],
      Amount: [],
      CountStops: [],
      NumberOrder: [],
      BusinessUnit: [],
      NameLocationSeller: [],
      DescriptionTypeTrip: [],
    },
    filterFlag: false,
    reloadTable: "",
  }),
  computed: {
    tHeader() {
      const oHeaders = [...this.showHeaders];
      if (this.actionsOn) {
        oHeaders.push({
          width: 140,
          value: "options",
          text: this.$t("components.app.datatable.options"),
          sortable: false,
          align: "right",
        });
      }
      return oHeaders;
    },
    items() {
      return this.newItems;
    },
    loadCursor() {
      return this.loader
        ? "row-pointer elevation-0 mt-0 parametable"
        : "elevation-0 mt-0 parametable";
    },

    showHeaders() {
      let headersCk = this.headersMap,
        local = this.local;

      if (headersCk.length > 0) {
        if (this.columnsOn) {
          localStorage.setItem(
            `col${this.name}`,
            JSON.stringify(this.selectedHeaders)
          );
        }
      } else {
        local = 0;
      }

      return local > 0
        ? this.selectedHeaders
        : this.headersMap.filter((s) => this.selectedHeaders.includes(s));
    },

    info() {
      let start = this.length === "Healthy" ? 0 : 1;
      let total = this.length === "Healthy" ? 0 : this.length;
      if (!this.noPagine) {
        return this.length
          ? `${this.$t(
              "components.app.datatable.items_displayed"
            )} ${start} - ${this.totalItems} ${this.$t(
              "components.app.datatable.of"
            )} ${total}`
          : "";
      } else {
        return this.length
          ? `${this.$t(
              "components.app.datatable.items_displayed"
            )} ${start} ${this.$t("components.app.datatable.of")} ${total}`
          : "";
      }
    },
    valHeight() {
      return this.height - 230;
    },

    computedScroll() {
      return "height: " + this.valHeight + "px" + ";" + "overflow-x: auto;" + "border: 3px solid #5c48f7;" + "border-radius: 12px;" + "background-color: #fff;";
    },

    //  URL Personalizada por tabla que guarda filtros seleccionados
    selectedFilterOnClickURL() {
        return `/Filters/saveFiltersTravelsUnassigned`;
    },

    //  URL Personalizada por tabla que trae filtros guardados
    getFilterOnClickURL() {
        return `/Filters/GetFiltersTravelsUnassigned`;
    },


    ...mapGetters({
      reloadTrips: "reloadTable/reloadTrips",
    }),



  },
  watch: {
    // Se ejecuta de nuevo cuando cambia el idioma
    headers(value) {
      this.headersMap = Object.values(value);
      this.selectedHeaders = value;
    },

    // reload table desde store
    reloadTrips(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData();
      }
    },


  },
  mounted() {
    this.headersMap = Object.values(this.headers);

    let colsLocalStorage = this.columnsOn
      ? localStorage.getItem(`col${this.name}`)
      : null;

    if (colsLocalStorage !== null) {
      let colsLocalStorageJson = JSON.parse(colsLocalStorage);
      colsLocalStorageJson.splice(
        colsLocalStorageJson.map((e) => e.text).indexOf("Opciones"),
        -1
      );
      this.selectedHeaders = colsLocalStorageJson;
      this.local = 1;
    } else {
      this.selectedHeaders = this.headersMap;
    }
    this.loadData();
    this.getFilterOnClick();


  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("reloadTable", ["saveReloadTrips"]),

    selectedFilterItems(name) {
      return this.selectedFilter[name];
    },
    descargar(file) {
      const worksheetData =
        this.$el.parentElement.getElementsByTagName("table")[0];
      if (file === "copy") {
        let range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          range.selectNodeContents(worksheetData);
          sel.addRange(range);
          document.execCommand("copy");
        }

        sel.removeAllRanges();

        this.showSnack({
          text: this.$t("components.app.datatable.clipboard_confirm"),
          title: this.$t("components.app.datatable.success"),
          name: "success",
        });
      }
      if (file === "xlsx" || file === "csv") {
        const xlsx = require("xlsx"),
          workbook = xlsx.utils.book_new(),
          worksheet = xlsx.utils.table_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet);
        return xlsx.writeFile(workbook, `Reporte_${this.name}.${file}`);
      }
    },
    onScroll(e) {
      if (!this.noPagine) {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.perPage);

          if (rows && this.pageUpdate <= this.totalPages) {
            this.pageUpdate++;
            this.loadData(this.pageUpdate);
            this.rowsConsult = rows;
          }
          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 500);
      }
    },
    restartColum() {
      this.headersMap = Object.values(this.headers);
      this.local = 0;
      this.selectedHeaders = this.headersMap;
    },
    cleanField() {
      this.search = "";
      this.loadDataNew();
    },
    async loadDataUpload() {
      this.loader = true;
      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };


      formData.append("file", this.file);

      await this.axios
        .post(this.urlUpload, formData, config)
        .then(function (response) {})
        .catch(function (error) {
          const e = error.response
            ? this.$t("components.app.datatable.error_message")
            : error.message.trim();
          this.showSnack({
            text: e,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    async loadLimitCount() {
      let searchProp = this.searchP !== "" ? this.searchP : this.search;

      let search = searchProp == "" ? "" : `?search=${searchProp}`;
      await this.axios.get(`${this.urlCount}${search}`).then((res) => {
        this.length = res.data;
      });
    },

    // Abre filtros
    loadFilter(fieldName) {
      let indexToUpdate = this.headers.findIndex(
        (item) => item.name == fieldName
      );
      if (!this.headers[indexToUpdate].showFilter) {
        this.$refs.selectFilter[indexToUpdate].loadData();
      }
      this.headers[indexToUpdate].showFilter = this.headers[indexToUpdate]
        .showFilter
        ? false
        : true;

      this.headers.forEach((element, index) => {
        if (element.name !== fieldName) {
          this.headers[index].showFilter = false;
        }
      });
    },

    // Guarda filtros seleccionados
    async selectedFilterOnClick(selected, name, URL) {
      this.filterFlag = true;
      this.selectedFilter[name] = selected;
      await this.axios
        .post(URL, this.selectedFilter)
        .then(() => {})
        .catch(function (error) {
          const e = error.response
            ? this.$t("components.app.datatable.error_message")
            : error.message.trim();
          this.showSnack({
            text: e,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loadDataNew();
          this.loader = false;
          this.filterFlag = false;
        });
    },

    // Trae filtros guardados
    async getFilterOnClick(getFilterOnClickURL) {
      await this.axios
        .get(getFilterOnClickURL)
        .then((response) => {
          if (response.data.objectFilter !== "null") {
            this.selectedFilter = JSON.parse(response.data.objectFilter);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loader = false;
        });
    },

    loadDataNew() {
      this.pageUpdate = 0;
      this.loadData();
    },

    async loadData(scrollPage) {
      await this.loadLimitCount();

      let searchProp = this.searchP !== "" ? this.searchP : this.search;

      let search = searchProp == "" ? "" : `?search=${searchProp}`,
        page = scrollPage ? scrollPage : 0;

      this.loader = true;

      try {
        let noPagine = !this.noPagine
          ? `/${this.perPage}/${page}${search}`
          : "";
        const response = await this.axios.get(`${this.url}${noPagine}`);
        if (!this.noPagine) {
          this.localItems = response.data;

          let totalPages = this.length / this.perPage;
          this.totalPages = parseInt(totalPages);

          this.newItems = scrollPage
            ? [...this.newItems, ...this.localItems]
            : [...this.localItems];
          this.totalItems = this.newItems.length;
        } else {
          this.newItems = response.data;
        }
      } catch (error) {
      } finally {
        this.loader = false;
        this.saveReloadTrips({ reloadTrips: false });
      }
    },
    clearFile() {
      this.$nextTick(() => {
        this.file = [];
      });
    },
    showModalUpload() {
      this.modalUpload = true;
    },
    closeModalUpload() {
      this.modalUpload = false;
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.tHeader;
      if (evt.newIndex >= headersTmp.length) {
        let k = evt.newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(evt.newIndex, 0, headersTmp.splice(evt.oldIndex, 1)[0]);
      this.anIncreasingNumber++;
      if (this.columnsOn) {
        localStorage.setItem(`col${this.name}`, JSON.stringify(this.tHeader));
      }
    },
  },
  directives: {
    "sortable-table": {
      inserted: (el, binding, vnode) => {
        if (vnode.context.dragColumnsOn) {
          el.querySelectorAll("th").forEach((draggableEl) => {
            let lastClassState = draggableEl.classList.contains("text-center");
            const observer = new MutationObserver((mutationsList) => {
              for (let i = 0; i < mutationsList.length; i++) {
                const mutation = mutationsList[i];
                if (
                  mutation.type === "attributes" &&
                  mutation.attributeName === "class"
                ) {
                  const currentClassState =
                    mutation.target.classList.contains("text-center");
                  if (lastClassState !== currentClassState) {
                    lastClassState = currentClassState;
                    if (!currentClassState) {
                      mutation.target.classList.add("text-center");
                    }
                  }
                }
              }
            });
            observer.observe(draggableEl, { attributes: true });
            draggableEl.classList.add("text-center");
          });
          Sortable.create(
            el.querySelector("tr"),
            binding.value ? { ...binding.value, handle: ".text-center" } : {}
          );
          el.addEventListener("scroll", () => {
            if (el.scrollTop !== lastScrollTop) {
              console.log("Scroll actualizado:", el.scrollTop);
              lastScrollTop = el.scrollTop;
            }
          });
        }
      },
    },
  },
};
</script>
<style scoped>
#virtual-scroll {
  overflow-y: auto;
  width: 100%;
  display: inline-block;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.6) !important;
}

#virtual-scroll >>> thead >>> tr >>> th {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.6) !important;
}


#virtual-scroll >>> thead tr:last-child th {
  background-color: #fff !important;
  /* border-bottom: 2px solid green !important; */
  /* border-top: 2px solid green !important; */
}

#virtual-scroll >>> thead tr:last-child th span {
  font-size: 13px !important;
  background: rgba(255, 255, 255, 0.6) !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: progress;
}

.border_primary {
  /* border: 3px solid #5c48f7 !important; */
  background: #ffffffc2 !important;
  border-radius: 12px;
  /* height: 50%;*/
}

#virtual-scroll {
  background: rgba(255, 255, 255, 0.6) !important;

}

#virtual-scroll >>> .v-data-table__wrapper {
  overflow-x: unset;
  overflow-y: unset;
  height: 40%;
}

.parametable >>> .actbtn {
  min-width: 35px !important;
  width: 45px !important;
}

.parametable tbody .text-center {
  display: table;
  padding: 0em !important;
  background: rgba(255, 255, 255, 0.6) !important;
}


#virtual-scroll >>> tbody tr {
  background: rgba(255, 255, 255, 0.6) !important;
}

.parametable th {
  font-size: 0.5em !important;
}

.v-list-item {
  min-height: 20px !important;
}

.v-application >>> .v-list-item__title {
  font-size: 0.8em;
}

.parametable >>> th.text-center {
  text-align: center !important;
}

.parametable >>> .v-input--selection-controls__input {
  margin: 0 auto !important;
}

.v-data-table-header >>> th {
  font-size: 0.8em !important;
  text-align: center !important;
  font-weight: normal;
}

#virtual-scroll >>> .v-data-table-header {
  top: 0;
}

.informacion-page {
  text-align: right;
  margin-right: 1em;
}

#virtual-scroll >>> .v-data-table__wrapper {
  height: 103%;
}

#virtual-scroll >>> tbody tr .clic,
#virtual-scroll >>> tbody tr .text-clic {
  cursor: pointer;
}

.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.header-text {
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
}

.list-filters {
  padding: 0 0 0 0;
  height: 30em;
}

.list-title {
  margin: 0 20px 15px 15px;
  font-size: 16px;
}

.list-item-filter {
  background-color: #fff;
}

.filters-item {
  margin: 0;
  width: 150px;
  background-color: #fff;
}

.v-list-item[data-v-0ae878d6] {
  min-height: 0px !important;
}

.menuFilter {
  top: 40px;
  left: 140px;
  background-color: #fff;
  width: 180px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
}

.menuFilter .v-list-item__title {
  white-space: pre-wrap;
}

.menuFilter >>> .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
.menuFilter >>> .v-text-field__details {
  display: none !important;
}

.space-btn-Massive-offer {
  height: 50px;
}

.loadtablebtn {
  position: absolute;
  z-index: 1;
  top: 44px;
  left: 200px;
  margin-left: 270px;
  display: none;
}

.row.mb-2.no-gutters {
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

span.header-title {
  font-weight: bolder;
  color: #0B0045;
}

.v-application .px-4 {
    padding-right: 40px !important;
    padding-left: 16px !important;
}

.v-data-table__wrapper > table > tbody > tr > td.text-center {
  font-size: 2em !important;
}

.v-select-list[data-v-ffd93a0c] .v-list-item__title {
  font-size: 0.7em !important;
  color: #666;
}



/* Pantallas  grandes (1300px o más) */
@media (min-width: 1300px) {
  #virtual-scroll >>> thead tr:last-child th span {
    font-size: 14px !important;
  }
}

/* Pantallas extra grandes (1600px o más) */
@media (min-width: 1600px) {
  #virtual-scroll >>> thead tr:last-child th span {
    font-size: 14px !important;
  }
}



</style>
